import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { Link, PageProps } from "gatsby";
import DocumentationSection from "components/docs/DocumentationSection";
import LINKS, { MERGE_PRICING_PAGE } from "components/docs/navigation/links";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import { FittedCodeExample, LeftCol, RightCol, RowWithoutNegativeMargin } from "./pulling-data";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import HubspotExampleContact from "../../../assets/images/docs/guides/remote-fields/hubspot-contact-example.svg";

/**
 * Creates an Overview for Field Mapping guide
 */
const RemoteFieldsPushingData = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Pushing Remote Field Classes";
  const description = "Push values for custom fields to your end-users’ CRM systems.";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} isBeta={true} className="pb-9">
        <PricingInfoCard className="mb-9 mt-0">
          This feature is only available to customers on our{" "}
          <b className="weight-semibold">Professional</b> and{" "}
          <b className="weight-semibold">Enterprise</b> plans. View the{" "}
          <a href={MERGE_PRICING_PAGE} target="_blank" rel="noreferrer">
            {" "}
            Merge Plans
          </a>{" "}
          to learn more.
        </PricingInfoCard>
      </HeaderBar>
      <DocumentationSection title="Endpoints">
        <p>
          Use the relevant Merge POST endpoint for a particular Common Model, with your desired
          Remote Field Class values included in your request body.
        </p>
        <p>
          For pushing values for standard fields, use the standard Merge Common Model fields, or if
          needed,{" "}
          <Link
            to={"https://docs.merge.dev/guides/merge-writes/programmatic/templates-conditional/"}
          >
            Linked Account or Integration parameters
          </Link>
          .
        </p>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Sending a POST request">
        <RowWithoutNegativeMargin className="mb-9 mt-8">
          <LeftCol xs={12} md={6}>
            <div className="mr-10">
              <p className="">There are two key pieces for writing to custom fields using Merge:</p>
              <ul>
                <li>
                  The <b className="weight-semibold">Merge ID</b> of the{" "}
                  <b className="weight-semibold">Remote Field Class</b>, which you can retrieve from
                  the <b className="weight-semibold">/remote-field-classes</b> endpoint for that
                  model
                </li>
                <li>
                  The <b className="weight-semibold">value</b> that you want to push to the
                  third-party for that field
                </li>
              </ul>
              <p>
                When sending a POST request to the <b className="weight-semibold">/contacts</b>{" "}
                endpoint, for example, these two data points should be included in the request body
                under the <code>remote_fields</code> array.
              </p>
            </div>
          </LeftCol>

          <RightCol xs={12} md={6}>
            <div className="fle flex-col h-full">
              <FittedCodeExample
                folder="remote-fields"
                codeBlockName="Example response - POST /contacts"
                fileBaseName="post-contacts-remote-fields-example"
                colorScheme="dark"
                hasLineNumbers={false}
                isVerticallyPadded={false}
                hideCopyButtonWithHeader={false}
                className="w-100 pt-0"
              />
            </div>
          </RightCol>
        </RowWithoutNegativeMargin>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column mr-10">
            <p className="">
              Once your POST request is successful, you can access your Remote Field Class metadata
              and values for the contact you just created by following our guide for{" "}
              <Link to={LINKS.REMOTE_FIELDS_PULLING_DATA.linkTo}>
                pulling Remote Field Class Data
              </Link>
              .
            </p>
            <p className="">
              You will also be able to see the custom field values you just wrote to the third-party
              on the relevant UI, as shown in this example from Hubspot.
            </p>
          </div>
          <div className="d-flex">
            <HubspotExampleContact className="mb-6" />
          </div>
        </div>
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default RemoteFieldsPushingData;
